<template>
  <v-row class="inner-content">
    <v-col>

      <v-card class="pb-3">
        <v-card-title>
          Create keywords
          <a class="ml-3" style="font-size: 0.8em;" href="https://docs.google.com/spreadsheets/d/1uMxSNNB6zPTYNFIG3gOT5OBmoxm4E1-lCbq7knG1wCA/edit#gid=1819068986" target="_blank"><v-icon>help</v-icon></a>
        </v-card-title>
        <v-card-text>

          <v-row>
            <v-col>
              <p>Create keywords by selecting an account and pasting keyword details into the field below. The corresponding negatives will also be created.</p>
            </v-col>
          </v-row>

          <v-form ref="form">

            <v-row>
              <v-col md="4">
                <BingAccountAutoComplete
                  v-model="selectedDestinationAccountID"
                  label="Destination account" 
                  @changed="destinationAccountChanged"
                ></BingAccountAutoComplete>
              </v-col>

              <v-col md="4">
                <BingAccountAutoComplete
                  v-model="selectedBaseAccountID"
                  label="Optional: base Bing account. Only the first keyword label found will be returned." 
                  :mandatory="false" 
                  @changed="baseAccountChanged"
                ></BingAccountAutoComplete>

                <BingCampaignAutoComplete 
                  v-model="selectedBaseCampaign"
                  label="Base Bing account campaign"
                  :accountID="selectedBaseAccountID"
                  :mandatory="false"
                  :showAdGroupRefresh="true"
                ></BingCampaignAutoComplete>

                <v-btn style="min-width: 165px;" class="mt-4" color="success" :loading="fillingFromBase" :disabled="!selectedDestinationAccountID || !selectedBaseCampaign" @click="fillFromBingBase()">Fill from Bing base</v-btn>
                <span class="ml-2" style="color: red;" v-show="!selectedDestinationAccountID && selectedBaseCampaign">Select destination account</span>
              </v-col>

              <v-col md="4">
                <GoogleAccountAutoComplete
                  v-model="selectedBaseGoogleAccountID"
                  label="Optional: base Google account" 
                  :mandatory="false" 
                  @changed="baseGoogleAccountChanged"
                ></GoogleAccountAutoComplete>

                <GoogleCampaignsMultiAutoComplete 
                  v-model="selectedBaseGoogleCampaignIDs"
                  :accountID="selectedBaseGoogleAccountID"
                  label="Base Google account campaigns" 
                  :mandatory="false"
                  :showAdGroupRefresh="true"
                ></GoogleCampaignsMultiAutoComplete>

                <v-btn style="min-width: 165px;" class="mt-4" color="success" :loading="fillingFromGoogle" :disabled="!selectedDestinationAccountID || selectedBaseGoogleCampaignIDs.length == 0" @click="fillFromGoogleBase()">Fill from Google base</v-btn>
                <span class="ml-2" style="color: red;" v-show="!selectedDestinationAccountID && selectedBaseGoogleCampaignIDs.length != 0">Select destination account</span>
              </v-col>

            </v-row>

            <v-row>
              <v-col md="12">
                <v-text-field style="font-size: 0.9em;" dense :placeholder="keywordsHeader" disabled></v-text-field>
                <v-textarea v-model="keywordsContent" clearable dense rows="10" hint="Paste keywords here. Columns should be TAB separated." style="background-color: lightyellow;"
                  required :rules="[v => !!v || 'Keywords are required']" @change="keywordsContentChanged">
                </v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="12">
                <v-btn color="success" :loading="validating" @click="create(true)">Validate</v-btn>
                <v-fade-transition mode="out-in">
                  <v-btn color="green darken-1" text v-show="showValid">Valid</v-btn>
                </v-fade-transition>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="12">
                <v-alert v-model="showErrorAlert" color="error" border="left" elevation="2" colored-border dismissible class="mt-4 ml-2 mr-2" icon="error" style="white-space: pre-line;">{{errorMsg}}</v-alert>
              </v-col>
            </v-row>
          </v-form>

          <v-row>
            <v-col md="12" :hidden="!validated">
              <v-simple-table class="mt-4">
                <thead>
                  <tr class="pb-0">
                    <th>Campaign</th>
                    <th>Topic</th>
                    <th>Keyword</th>
                    <th>Status</th>
                    <th>Label</th>
                    <th>S.MatchType</th>
                    <th># existing ad groups</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in parsedKeywords" :key="item.campaign+item.topic+item.keyword">
                    <td>{{item.campaign}}</td>
                    <td>{{item.topic}}</td>
                    <td>{{item.keyword}}</td>
                    <td>{{item.status}}</td>
                    <td>{{item.label}}</td>
                    <td>{{item.match_type_segments_str}}</td>
                    <td>{{item.ad_group_count}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <p class="mt-6" v-if="validated && additionalRowsText">{{additionalRowsText}}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="12" :hidden="!validated">
              <v-btn class="mt-0" color="primary" :loading="addingJob" @click="create(false)">Add creation job</v-btn>
              <v-fade-transition mode="out-in">
                <v-btn color="primary darken-1" text v-show="showJobAdded">Added</v-btn>
              </v-fade-transition>
            </v-col>
          </v-row>

        </v-card-text>

        <h4 class="mb-2 mt-4 ml-3">Keyword creation jobs
          <v-btn v-show="!showJobs" icon @click="toggleShowJobs(true)">
            <v-icon small>visibility</v-icon>
          </v-btn>
          <v-btn v-show="showJobs" icon @click="toggleShowJobs(false)">
            <v-icon small>visibility_off</v-icon>
          </v-btn>
        </h4>

        <v-row class="mr-3">
          <v-col>
            <SystemJobsTable v-show="showJobs" :headers="jobHeaders" :jobType="jobType" :updateKey="updateTableKey"></SystemJobsTable>
          </v-col>
        </v-row>
      </v-card>

    </v-col>
  </v-row>

</template>

<script>
import ccMixin from '@/mixins/contentCreator'
import formsMixin from '@/mixins/forms'

export default {
  name: 'BingKeywords',

  data () {
    return {
      keywordsContent: '',
      parsedKeywords: [],
      additionalRowsText: '',
      keywordsHeader: 'Campaign   Topic   Keyword   Status   Label   S.MatchType',
      selectedDestinationAccountID: 0,
      selectedBaseAccountID: 0,
      selectedBaseCampaign: null,
      selectedBaseGoogleAccountID: 0,
      selectedBaseGoogleCampaignIDs: [],
      fillingFromGoogle: false,
      validating: false,
      addingJob: false,
      fillingFromBase: false,
      lsSelectedDestinationAccount: 'contentCreator_selectedDestinationAccountBing',
      lsSelectedBaseAccount: 'contentCreator_selectedBaseAccountBing',
      lsSelectedBaseGoogleAccount: 'contentCreator_selectedBaseAccount',
      lsContent: 'contentCreator_keywordsContentBing',
      lsShowJobs: 'contentCreator_showJobs',
      validated: false,
      showValid: false,
      showJobAdded: false,
      showErrorAlert: false,
      errorMsg: '',
      showJobs: false,
      updateTableKey: 'init',
      jobType: 'Bing - create keywords content',
      jobHeaders: [
        { text: 'User', value: 'user_name' },
        { text: 'Status', value: 'status' },
        { text: 'Account', value: 'text1' },
        { text: '# keywords', value: 'text2' },
        { text: 'Entry at', value: 'entry_at_time' },
        { text: 'Completed at', value: 'completed_at_time' },
        { text: 'External message', value: 'external_message' },
      ],
    }
  },

  mixins: [ccMixin, formsMixin],

  computed: {
    baseEndpoint () {
      return '/a/bing/accounts/' + this.selectedBaseAccountID + '/campaigns/' + this.selectedBaseCampaign.campaign_id + '/content-creator/keywords'
    },

    baseGoogleEndpoint () {
      return '/a/bing/accounts/' + this.selectedDestinationAccountID + '/content-creator/google-keywords?google_account_id=' + this.selectedBaseGoogleAccountID + '&xper_page=2000'
    },

    destEndpoint () {
      return '/a/bing/accounts/' + this.selectedDestinationAccountID + '/content-creator/keywords'
    },
  },

  components: {
    BingAccountAutoComplete: () => import('@/components/autocompletes/BingAccountAutoComplete.vue'),
    BingCampaignAutoComplete: () => import('@/components/autocompletes/BingCampaignAutoComplete.vue'),
    GoogleAccountAutoComplete: () => import('@/components/autocompletes/GoogleAccountAutoComplete.vue'),
    GoogleCampaignsMultiAutoComplete: () => import('@/components/autocompletes/GoogleCampaignsMultiAutoComplete.vue'),
    SystemJobsTable: () => import('@/components/tables/SystemJobsTable.vue'),
  },

  methods: {
    baseAccountChanged: function () {
      if (!this.selectedBaseAccountID) {
        this.selectedBaseAccountID = 0
      }

      this.selectedBaseCampaign = null

      localStorage.setItem(this.lsSelectedBaseAccount, JSON.stringify(this.selectedBaseAccountID))
    },

    baseGoogleAccountChanged: function () {
      if (!this.selectedBaseGoogleAccountID) {
        this.selectedBaseGoogleAccountID = 0
      }

      this.selectedBaseGoogleCampaignIDs = []

      localStorage.setItem(this.lsSelectedBaseGoogleAccount, JSON.stringify(this.selectedBaseGoogleAccountID))
    },

    create (validateOnly = true) {
      this.showErrorAlert = false
      this.additionalRowsText = ''
      if (!this.$refs.form.validate()) {
        return false
      }

      var action = 'addingJob'
      var showVar = 'showJobAdded'
      if (validateOnly) {
        this.validated = false
        action = 'validating'
        showVar = 'showValid'
      } else {
        this.toggleShowJobs(true)
      }

      this[action] = true

      var splitLines = this.keywordsContent.split('\n')
      var obj = {
        'lines': splitLines,
        'validate_only': validateOnly
      }
      var body = JSON.stringify(obj)

      this.$http.post(this.destEndpoint, body).then(resp => {
        if (resp.data.data.error_msgs) {
          this.errorMsg = this.forms_getValidationDisplay(resp.data.data.error_msgs, 'error(s)')
          this.showErrorAlert = true
          this.parsedKeywords = []
        } else {
          if (resp.data.data.parsed_keywords) {
            const maxTableRecs = 5
            if (resp.data.data.parsed_keywords.length > maxTableRecs) {
              this.parsedKeywords = resp.data.data.parsed_keywords.slice(0, maxTableRecs)
              this.additionalRowsText = 'Showing first ' + maxTableRecs + ' records. A further ' + (resp.data.data.parsed_keywords.length - maxTableRecs) + ' records are not shown.'
            } else {
              this.parsedKeywords = resp.data.data.parsed_keywords
            }
            this.updateTableKey = this.$dateTime.now().toString()
            this.validated = true
            this[showVar] = true
            this.timer = setTimeout(() => { this[showVar] = false }, this.cc_buttonDisplayMS)
          } else {
            this.parsedKeywords = []
          }
        }
      })
      .catch(errResp => {
        this.validated = false
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.destEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this[action] = false)
    },

    destinationAccountChanged: function () {
      localStorage.setItem(this.lsSelectedDestinationAccount, JSON.stringify(this.selectedDestinationAccountID))
    },

    fillFromBingBase: function () {
      if (this.selectedBaseAccountID == 0 || !this.selectedBaseCampaign) {
        return
      }

      this.keywordsContent = ''
      this.validated = false
      this.showErrorAlert = false
      this.fillingFromBase = true

      // fetch DB data for selected campaign
      var myURL = this.baseEndpoint

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {

          // put the DB data into tab-separated format
          var content = ''
          resp.data.data.forEach(v => {
            content += v.campaign
            content += '\t' + v.topic
            content += '\t' + v.keyword
            content += '\t' + v.status
            content += '\t' + v.label
            content += '\t' + v.match_type_segments_str
            content += '\n'
          })
          if (content) {
            this.keywordsContent = content
          } else {
            this.keywordsContent = 'Keywords unavailable because ad groups for this account either do not exist, or have not yet been downloaded. Please refresh the base account ad groups above, and then try again.'
          }
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.fillingFromBase = false)
    },

    fillFromGoogleBase: function () {
      if (this.selectedBaseGoogleAccountID == 0 || this.selectedBaseGoogleCampaignIDs.length == 0) {
        return
      }

      this.keywordsContent = ''
      this.validated = false
      this.showErrorAlert = false
      this.fillingFromGoogle = true

      // fetch DB data for selected campaign
      var myURL = this.baseGoogleEndpoint + '&campaign_id=' + this.selectedBaseGoogleCampaignIDs.join('|')

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {

          // put the DB data into tab-separated format
          var content = ''
          resp.data.data.forEach(v => {
            content += v.campaign
            content += '\t' + v.topic
            content += '\t' + v.keyword
            content += '\t' + v.status
            content += '\t' + v.label
            content += '\t' + v.match_type_segments_str
            content += '\n'
          })
          if (content) {
            this.keywordsContent = content
          } else {
            this.keywordsContent = 'Keywords unavailable because ad groups for this account either do not exist, or have not yet been downloaded, or the keywords already exist in the destination campaign.'
          }
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.fillingFromGoogle = false)
    },

    keywordsContentChanged: function () {
      this.validated = false
      this.showValid = false
      this.showErrorAlert = false

      // save to local storage
      localStorage.setItem(this.lsContent, JSON.stringify(this.keywordsContent))
    },

    toggleShowJobs (show) {
      this.showJobs = show

      // save to local storage
      localStorage.setItem(this.lsShowJobs, JSON.stringify(this.showJobs))
    }
  },

  created: function () {
    // if present, use local selected accounts
    if (localStorage.getItem(this.lsSelectedDestinationAccount)) {
      this.selectedDestinationAccountID = JSON.parse(localStorage.getItem(this.lsSelectedDestinationAccount))
      this.destinationAccountChanged()
    }
    if (localStorage.getItem(this.lsSelectedBaseAccount)) {
      this.selectedBaseAccountID = JSON.parse(localStorage.getItem(this.lsSelectedBaseAccount))
      this.baseAccountChanged()
    }
    if (localStorage.getItem(this.lsSelectedBaseGoogleAccount)) {
      this.selectedBaseGoogleAccountID = JSON.parse(localStorage.getItem(this.lsSelectedBaseGoogleAccount))
      this.baseGoogleAccountChanged()
    }

    // if present, use content
    if (localStorage.getItem(this.lsContent)) {
      this.keywordsContent = JSON.parse(localStorage.getItem(this.lsContent))
    }

    // if present, use showJobs
    if (localStorage.getItem(this.lsShowJobs)) {
      this.showJobs = JSON.parse(localStorage.getItem(this.lsShowJobs))
    }
  },

}
</script>

<style scoped>
</style>